import $ from 'jquery';
import { insertCommerceButton } from './insert-commerce-button';

// HACK: 本来は, GraphQL などから生成したエンティティを参照すべき
export interface Product {
  id: number;
  name: string;
  thumb_url: string;
  has_pc_label: boolean;
}

export interface Pattern {
  id: number;
  name: string;
  release_date: string;
  post_count: number;
  thumb_url: string;
  status: string;
  status_i18n: string;
  feature_labels: FeatureLabel[];
  post_thumb_urls: string[];
  commerce_sales_status: string | null;
}

export interface ProductPatterns {
  product: Product;
  product_patterns: Pattern[];
}

export interface FeatureLabel {
  label_text: string;
  label_style: string;
}

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const productsShow = () => $(() => {
  const open_spec_modal = '.product-show-inner__heading__openSpecModal';
  const product_spec = '.product-spec';

  setupSplide();

  $('.btn-fb').on('click', function(event: JQueryMouseEventObject) {
    event.preventDefault();

    // @ts-ignore
    fbq('track', 'Lead');

    const href = $(this).attr('href');

    if (href) {
      location.href = href;
    }
  });

  $(open_spec_modal).on('click', function() {
    $(product_spec).removeClass('hide_modal');
    $('#modal-background').show();
  });

  $('#modal-background, .product-spec__close').on('click', function() {
    $(product_spec).addClass('hide_modal');
    $('#modal-background').hide();
  });

  insertCommerceButton();
});


const setupSplide = () => {
  const thumbnail = '.ProductPatternsListForProductShow__item';
  const animationSpeedMs = 400;

  // @ts-ignore
  const splide = new Splide('.product-show-image.splide', {
    type: 'loop',
    arrows: false,
    pagination: false,
    speed: animationSpeedMs,
  }).mount();

  const { Elements } = splide.Components;
  const slides = Elements.slides

  splide.on('move', function (newIndex: number, prevIndex: number) {
    const newHeight = $(slides[newIndex]).children('figure').height();
    const prevHeight = $(slides[prevIndex]).children('figure').height();

    if (newHeight !== prevHeight) {
      $('.splide__list').animate( { height: `${newHeight}px` }, animationSpeedMs).css('overflow', 'visible');
    }
  });

  $(thumbnail).on('click', function() {
    splide.go($(thumbnail).index(this))
  });

  $(window).on('resize', function() {
    const newHeight = $(slides[splide.index]).children('figure').height();

    $('.splide__list').css('height', `${newHeight}px`)
  });
}
