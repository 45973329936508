import $ from 'jquery';

export const usersEdit = () => $(() => {
  $('#birthDateInput').change(function() {
    const dob = new Date($(this).val() as string);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear() - (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate()) ? 1 : 0);
    let ageGroup;
    if (age < 10) {
      ageGroup = "年代不明";
    } else if (age >= 55) {
      ageGroup = "50代後半"
    } else {
      const decade = Math.floor(age / 10) * 10;
      const remainder = age % 10;
      const decadeString = decade + '代';
      const periodString = remainder < 5 ? '前半' : '後半';
      ageGroup = decadeString + periodString;
    }

    $('#birthDateToAgeGroup').text("（" + ageGroup + "）");
  });

  function setProfileImage() {
    const data = formImages[formImages.length - 1];
    $(".UserEdit__profileImg__icon").attr('src', data);
    $("#profile_img_param").remove();
    $(".UserEdit__profileImg").append("<input type='hidden' id='profile_img_param' name='user[profile_img_url]' value="+data+">")
  }
  var formImages: string[] = []
  var form = document.querySelector<HTMLInputElement>("#profile-img-url");
  form?.addEventListener("change", function(evt) {
    if (!form) return;
    if (!form.files) return;
    var file = form.files[0];
    var fr = new FileReader();
    fr.onload = function(evt: ProgressEvent<FileReader>): void {
      if (evt.target?.result) {
        const dataUrl = evt.target.result as string;
        formImages.push(dataUrl);
        setProfileImage();
      }
    };
    fr.readAsDataURL(file);
  }, false);

  $(document).ready(function(){
    $('.UserEdit__isPublic__field').each(function(){
      const checkedIcon = $(this).siblings('.UserEdit__isPublic__checked');
      const uncheckedIcon = $(this).siblings('.UserEdit__isPublic__unchecked');
      if ($(this).val() === 'false'){
        checkedIcon.show();
        uncheckedIcon.hide();
      }else{
        checkedIcon.hide();
        uncheckedIcon.show();
      }
    });
  });

  modalSelect('.UserEdit__interests', 'interest_ids', 5);
  modalSelect('.UserEdit__favorite-brands', 'favorite_brand_ids', undefined);
  modalSelect('.UserEdit__occupations', 'occupation_ids', undefined);

  searchBrand();
  twitterLink();

  workingSalonModal();

  bindCheckBox('gender_is_not_public', 'user_user_profile_attributes_gender_is_public');
  bindCheckBox('skin_color_is_not_public', 'user_user_profile_attributes_skin_color_is_public');
  bindCheckBox('hair_type_is_not_public', 'user_user_profile_attributes_hair_type_is_public');
  bindCheckBox('skeleton_type_is_not_public', 'user_user_profile_attributes_skeleton_type_is_public');
  bindCheckBox('face_type_is_not_public', 'user_user_profile_attributes_face_type_is_public');
  bindCheckBox('occupations_are_not_public', 'user_user_profile_attributes_occupations_are_public');
  bindCheckBox('interests_are_not_public', 'user_user_profile_attributes_interests_are_public');

  bindCheckBox('skin_type_is_not_public', 'user_skin_type_is_public');
  bindCheckBox('age_type_is_not_public', 'user_age_type_is_public');
  bindCheckBox('personal_color_is_not_public', 'user_personal_color_is_public');
});

// 非公開にする場合にチェックが入り､カラムの値は公開する場合にtrueで逆のため､
// 見た目の表示用のチェックボックスと実際の値のhidden_fieldの2つを用意していて値を同期するようにしている
const bindCheckBox = (checkBoxId: string, hiddenInputId: string) => {
  $(`#${checkBoxId}`).on('change', function() {
    $(`#${hiddenInputId}`).val(String(!$(this).prop('checked')));
  })
}

const modalSelect = (className: string, name: string, limit?: number) => {
  const listElements = `${className} .UserEditModal__list-element`;
  const alertFooter = `${className} .UserEditModal__footer-alert`;
  const clearBtn = `${className} .UserEditModal__clear_btn`;
  const decideBtn = `${className} .UserEditModal__decide_btn`;
  const hiddenFields = `${className} .UserEdit__hidden_fields`;
  const hiddenFieldInputs = `${className} .UserEdit__hidden_fields input[type="hidden"]`;
  const modalSelectTextPresent = `${className} .UserEdit__form__modal-select-text-present`;
  const modalSelectArea = `${className} .UserEdit__form__modal-select-area`;

  const listElementClassName = '.UserEditModal__list-element';

  const updateButtonStates = () => {
    const isSelected = $(listElements).hasClass('UserEditModal__list-element-selected');
    $(clearBtn).prop('disabled', !isSelected);
    $(alertFooter).hide();

    const selectedIds = $(listElements).filter('.UserEditModal__list-element-selected').map((index, element) =>
      $(element).data('id')
    ).get();
    const valuesArray = $(hiddenFieldInputs).map((index, element) =>
      $(element).val()
    ).get();

    $(decideBtn).prop('disabled', selectedIds.sort().join(',') === valuesArray.sort().join(','));
  }

  $(className).on('click', listElementClassName, function() {
    if (limit && !$(this).hasClass('UserEditModal__list-element-selected') && $(listElements).filter('.UserEditModal__list-element-selected').length >= limit) {
      $(alertFooter).show();
      return;
    };

    $(this).toggleClass('UserEditModal__list-element-selected');
    updateButtonStates();
    $(alertFooter).hide();
  });

  $(clearBtn).on('click', function() {
    $(listElements).removeClass('UserEditModal__list-element-selected');
    updateButtonStates();
  });

  $(decideBtn).on('click', function() {
    const selected = $(listElements).filter('.UserEditModal__list-element-selected').map((index, element) => ({
      id: $(element).data('id'),
      name: $(element).data('name')
    })).toArray();

    const htmlString = selected.map(item =>
      `<input multiple="multiple" value="${item.id}" autocomplete="off" type="hidden" name="user[user_profile_attributes][${name}][]" id="user_user_profile_attributes_${name}_${item.id}">`
    ).join('');

    const text = selected.map(item => item.name).join(', ');

    $(hiddenFields).html(htmlString);
    $(modalSelectTextPresent).text(text);

    if (selected.length > 0) {
      $(modalSelectArea).addClass('UserEdit__form__modal-select-present').removeClass('UserEdit__form__modal-select-empty');
    } else {
      $(modalSelectArea).removeClass('UserEdit__form__modal-select-present').addClass('UserEdit__form__modal-select-empty');
    }
  });
}

type Brand = { id: string, name: string, image_url: string }

const searchBrand = () => {
  $('.Search input[type="text"]').keyup(function(e) {
    e.preventDefault();

    const input = $.trim($(this).val() as string);
    if(!input) return;

    return $.ajax({
      url: '/brands/ajax_search',
      type: 'GET',
      data: { text: input, use_image: true, limit: 20 },
      dataType: 'json'
    }).done(function(res) {
      const list = $('.UserEdit__favorite-brands .UserEditModal__list').clone()
      list.find('.UserEditModal__list-element:not(.UserEditModal__list-element-selected)').remove();

      const selectedIds = list.find('.UserEditModal__list-element-selected').map((index, element) =>
        $(element).data('id')
      ).get();

      const html = res
        .filter((brand: Brand) => !selectedIds.includes(brand.id) )
        .map((brand: Brand) => {
          return `<li class="UserEditModal__list-element" data-id="${brand.id}" data-name="${brand.name}">
            <img src="${brand.image_url}">
              <div class="UserEditModal__list-element-background"></div>
              <div class="UserEditModal__list-element-name">${brand.name}</div>
            </li>
          `
        }).join('');

      list.append(html);
      $('.UserEdit__favorite-brands .UserEditModal__list').html(list.html());
    });
  });
}

const twitterLink = () => {
  $('.UserEdit__form__twitter-link-button').on('click', function(e) {
    e.preventDefault();

    $.ajax({
      url: '/users/backup_editing',
      type: 'GET',
      data: $('form.edit_user')
        .find(':input')
        .not('[name="authenticity_token"], [name="_method"]')
        .serialize(),
    }).done(function () {
      document.getElementById('UserEdit__twitter-oauth')?.click();
    });
  });
}

const workingSalonModal = () => {
  const inputs = '.UserEditModal__working-salon-form input'
  const clearBtn = '.UserEdit__working-salon .UserEditModal__clear_btn';
  const decideBtn = '.UserEdit__working-salon .UserEditModal__decide_btn';
  const modalSelectTextPresent = '.UserEdit__working-salon .UserEdit__form__modal-select-text-present';
  const modalSelectArea = '.UserEdit__working-salon .UserEdit__form__modal-select-area';

  $(inputs).on('keyup', function () {
    updateButtonStates();
  })

  $(decideBtn).on('click', function() {
    $(inputs).toArray().forEach(element => {
      const newValue = $(element).val();
      $(`.UserEdit__working-salon .UserEdit__hidden_fields input[name="user[user_working_salon_attributes][${$(element).attr('name')}]"]`).val(newValue || '');
    });

    const values = $(inputs).toArray().map(element => {
      const newValue = $(element).val();
      if (newValue === "") return undefined

      if ($(element).attr('name') === 'years_of_experience') {
        return `${newValue}年`;
      }
      return newValue;
    }).filter(val => val !== undefined);

    $(modalSelectTextPresent).text(values.join(", "));

    if (values.length > 0) {
      $(modalSelectArea).addClass('UserEdit__form__modal-select-present').removeClass('UserEdit__form__modal-select-empty');
    } else {
      $(modalSelectArea).removeClass('UserEdit__form__modal-select-present').addClass('UserEdit__form__modal-select-empty');
    }
  })

  $(clearBtn).on('click', function() {
    $(inputs).toArray().forEach(element => {
      $(element).val('');
    })
    updateButtonStates();
  })

  const updateButtonStates = () => {
    const isChange = $('.UserEditModal__working-salon-form input').toArray().some(element => {
      const val = $(`.UserEdit__working-salon .UserEdit__hidden_fields input[name="user[user_working_salon_attributes][${$(element).attr('name')}]"]`).val();
      return val !== $(element).val();
    });

    $(decideBtn).prop('disabled', !isChange);

    const isEmpty = !$('.UserEditModal__working-salon-form input').toArray().some(element => {
      return $(element).val() !== "";
    });

    $(clearBtn).prop('disabled', isEmpty);
  }
}
