export const anchorWithContentVisibility = () => $(() => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');

  anchorLinks.forEach(anchorLink => {
    anchorLink.addEventListener('click', () => {
      const contentVisibilityElements = document.querySelectorAll('.ProductListArticle__item');
      contentVisibilityElements.forEach(element => {
        element.classList.add('visible');
      });
    });
  });
});
