import $ from 'jquery';

export const mallPoint = () => $(() => {
  $('#user-part-point__radio-number-field').on('input',function(){
    const point = $(this).val()
    if (point !== '') {
      $('#type_arbitrary').prop("checked",true);
    } else {
      $('#type_arbitrary').prop("checked",false);
      $('#type_nothing').prop("checked",true);
    }
  });
});
