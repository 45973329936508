import $ from 'jquery';
import { insertCommerceButton } from './insert-commerce-button';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const productPatternShow = () => $(() => {
  setupSplide();
  insertCommerceButton();
});

const setupSplide = () => {
  const thumbnail = '.product-patterns-list-selection__item';
  const totalCount = $(thumbnail).length;

  if (totalCount <= 1) {
    $('.pagination_wrapper').hide();
    return;
  }

  // @ts-ignore
  const splide = new Splide('.ProductPatternsImageContainer__relative .splide', {
    arrows: false,
    type: 'loop',
  })

  $(thumbnail).each(function () {
    const index = $(thumbnail).index(this);
    $(this).attr('data-index', index);
  });

  const maxDots = 5;

  const transformDots = (nextSlide: number) => {
    const totalCount = $(thumbnail).length;
    if (totalCount > maxDots) {
      // nextSlideの場所によってdotsの位置を変える
      // - 両端の場合 → 端に位置するようにする
      // - 両端より1つ内側の場合 → 両端から1つ手前に位置するようにする
      // - それ以外 → 中心に位置するようにする
      $('.splide__pagination').css('transform', `translateX(${(Math.max(0, Math.min(nextSlide - 2, totalCount - maxDots))) * -10}px)`);

      $('.splide__pagination').find('li.small').removeClass('small')
      // 左端のドットより左に続きがある場合は小さい表示にする
      if (nextSlide - 2 > 0) {
        $('.splide__pagination').find('li').eq(Math.min(nextSlide - 2, totalCount - 5)).addClass('small')
      }
      // 右端のドットより右に続きがある場合は小さい表示にする
      if (nextSlide + 2 < totalCount - 1) {
        $('.splide__pagination').find('li').eq(Math.max(nextSlide + 2, 4)).addClass('small')
      }
    }
  }

  splide.on( 'mounted', function () {
    transformDots(0)
  });

  splide.mount();

  $(thumbnail).on('click', function () {
    splide.go($(thumbnail).index(this))
  });

  splide.on('move', function (newIndex: number) {
    transformDots(newIndex);
  });
}
