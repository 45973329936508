import $ from 'jquery';

export const scrollbar = () =>
  $(() => {
    const scrollable = $('.scroll__scrollable');
    const scrollbar = $('.scroll__scrollbar');
    const scrollbarThumb = $('.scroll__scrollbar-thumb');
    const adjustment = $('.scroll__adjustment');

    var scrollableHeight: number | undefined = undefined;
    var adjustmentHeight: number | undefined = undefined;
    var scrollbarHeight: number | undefined = undefined;
    var scrollbarTrack: number | undefined = undefined;

    const adjustHeight = () => {
      scrollableHeight = scrollable.height();
      adjustmentHeight = adjustment.outerHeight();

      if (scrollableHeight === undefined) return;
      if (adjustmentHeight === undefined) return;

      if (scrollableHeight >= adjustmentHeight) {
        scrollbar.hide();
      } else {
        scrollbar.show();
      }

      scrollbarHeight = (scrollableHeight * scrollableHeight) / adjustmentHeight;
      scrollbarThumb.css('height', scrollbarHeight);
      scrollbarTrack = scrollableHeight - scrollbarHeight;
    };

    adjustHeight();

    var timeout: NodeJS.Timeout | null = null;
    const resizeObserver = new ResizeObserver(function () {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        adjustHeight();
      }, 100); // 直後だと高さ取得がずれるため少し遅らせる
    });

    resizeObserver.observe(scrollable[0]);

    scrollable.on('scroll', function () {
      const scrollTop = $(this).scrollTop();
      if (scrollTop === undefined) return;
      if (scrollableHeight === undefined) return;
      if (adjustmentHeight === undefined) return;
      if (scrollbarTrack === undefined) return;

      const offset = (scrollTop * scrollbarTrack) / (adjustmentHeight - scrollableHeight);

      scrollbarThumb.css('transform', 'translateY(' + offset + 'px)');
    });

    var active = false; // つまみを操作しているかどうか
    var scrollbarThumbCursorY: number | null = null; // つまみ内のクリック位置

    scrollbarThumb.on('mousedown', function (event) {
      const offset = $(this).offset();
      if (offset === undefined) return;

      active = true;
      scrollbarThumbCursorY = event.pageY - offset!.top;
    });

    $(document).on('mouseup', function () {
      active = false;
    });

    $(document).on('mousemove', function (event) {
      if (!active) return;
      if (scrollbarThumbCursorY === null) return;

      const scrollBarOffset = scrollbar.offset();
      const scrollBarThumbOffset = scrollbarThumb.offset();

      if (!scrollBarOffset) return;
      if (!scrollBarThumbOffset) return;
      if (scrollableHeight === undefined) return;
      if (adjustmentHeight === undefined) return;
      if (scrollbarTrack === undefined) return;

      const scrollbar_thumb_y = Math.max(
        Math.min(((event.pageY - scrollBarOffset.top) / scrollbarTrack) * scrollbarTrack - scrollbarThumbCursorY, scrollbarTrack),
        0,
      );

      scrollbarThumb.css('transform', 'translateY(' + scrollbar_thumb_y + 'px)');
      scrollable.scrollTop(((scrollBarThumbOffset.top - scrollBarOffset.top) / scrollbarTrack) * (adjustmentHeight - scrollableHeight));
    });

    $(document).on('selectstart', function () {
      if (active) return false;
    });
  });
