import $ from 'jquery';

const LABEL_CLASS_SELECTOR = '.ProductElementsModal__labels-label';
const LABEL_ACTIVE_CLASS_NAME = 'ProductElementsModal__labels-label-active';
const ELEMENT_HIDE_CLASS_NAME = 'ProductElementsModal__element-hide';

export const productElements = () =>
  $(() => {
    const modal = $('.ProductElementsModal');

    $(LABEL_CLASS_SELECTOR).on('click', function () {
      if ($(this).hasClass(LABEL_ACTIVE_CLASS_NAME)) return;

      $(LABEL_CLASS_SELECTOR).removeClass(LABEL_ACTIVE_CLASS_NAME);
      $(this).addClass(LABEL_ACTIVE_CLASS_NAME);

      const text = $(this).data('text');

      // 表示件数が減った場合にmodalの高さも縮んでしまい見ずらいので元の高さになるように固定
      modal.css('height', `${modal.height()}px`);

      $('.ProductElementsModal__element').each(function (i, element) {
        if (
          text === '全て' ||
          $(element)
            .find('.ProductElementsModal__element-labels-label')
            .toArray()
            .some((e) =>
              e.innerText === text ||
              (text === '保湿' && (e.innerText === "保湿（うるおい）" || e.innerText === "保湿（エモリアント）"))
            )
        ) {
          $(element).removeClass(ELEMENT_HIDE_CLASS_NAME);
        } else {
          $(element).addClass(ELEMENT_HIDE_CLASS_NAME);
        }
      });
    });

    $(window).on('resize', () => {
      modal.css('height', '');
    });
  });
