import $ from 'jquery';
import confetti from 'canvas-confetti';
import toastr from 'toastr';

export const webviewGacha = (type: 'commerce' | 'campaign') =>
  $(() => {
    const baseClass = type === 'commerce' ? '.commerce-gacha' : '.campaign-gacha';
    const path = `/webview/${type}/gacha`;

    $(`${baseClass}`).on('click', `${baseClass}__lottery-button`, () => {
      $(`${baseClass}__result`).html('');
      $(`${baseClass}__result-modal`).hide();
      $('body').css('overflow-y', 'hidden');
      $(`${baseClass}__modal-background`).show();
      $(`${baseClass}__loading`).show();
      $.ajax({
        url: `${path}/play`,
        method: 'POST',
      })
        .done((res) => {
          $.ajax({
            url: `${path}/button`,
            method: 'GET',
          }).done((ires) => {
            $(`${baseClass}__lottery-button`).replaceWith(ires);
          });
          setTimeout(function () {
            $(`${baseClass}__result`).html(res);
            $(`${baseClass}__loading`).hide();
            $(`${baseClass}__result-modal`).show();
            if (!res.includes('result_lose')) {
              confetti();
            }
          }, 2000);
        })
        .fail((res) => {
          // todo エラーハンドリングをちゃんとやる
          $(`${baseClass}__loading`).hide();
          $(`${baseClass}__modal-background`).hide();
          $(`${baseClass}__result-modal`).hide();
          toastr.error(res.responseJSON.message, undefined, {
            positionClass: 'toast-bottom-center',
          });
        });
    });

    $(`${baseClass}__result`).on('click', `${baseClass}__result-modal__close`, () => {
      $('body').css('overflow-y', 'auto');
      $(`${baseClass}__modal-background`).hide();
      $(`${baseClass}__result-modal`).hide();
    });
  });
