import $ from 'jquery';

export const usersShow = () => $(() => {
  const bioElement = $('.UserHeader__bio');
  const readMoreElement = $('.UserHeader__bio--readMore');
  bioElement.click(function () {
    bioElement.css('max-height', 'none');
    readMoreElement.css('display', 'none');
  })
});
