import $ from 'jquery';

export const shoppingFaqIndex = () => {
  window.addEventListener('load', () => {
    const match = window.location.hash.match(/(?:category-)(.+)/);
    if (match == null) {
      return;
    }
    const categoryId = match[1];

    ($(`#faq${categoryId}`) as any).collapse('show');
  });
};
