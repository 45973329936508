import $ from 'jquery';

export const search = () => $(() => {
  $('.Search').each(function(index, element) {
    const input = $(element).children('input[type="text"]');
    const button = $(element).children('button');

    const toggleTextFormClearButton = () => {
      if ((input.val() as string).length > 0) {
        button.show();
      } else {
        button.hide();
      }
    }

    const clearTextForm = () => {
      input.val('');
      button.hide();
    };

    toggleTextFormClearButton();

    input.on('keyup', toggleTextFormClearButton);
    button.on('click', clearTextForm);
  })
});
