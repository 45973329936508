import $ from 'jquery';

export const earnedPointBreakdownModal = () => $(() => {
  $('.product-commerce__point--detail').on('click', function () {
    $('#modal-background').show();
    $('.EarnedPointBreakdownModal').show();
  });

  const hideModal = () => {
    $('#modal-background').hide();
    $('.EarnedPointBreakdownModal').hide();
  }

  $('#modal-background, .EarnedPointBreakdownModal__close').on('click', hideModal);
});
