import $ from 'jquery';

export const anchorTab = () => $(() => {
  const container = document.querySelector('.anchor-tab__flex-container') as HTMLElement;
  if (!container) return;

  const hiddenInactive = container.dataset.hiddenInactive;
  const links = Array.from(container.children) as HTMLElement[];
  const targets = links.map((link) => document.getElementById(link.dataset['anchor'] || ''));

  if (hiddenInactive === 'true') {
    links.forEach((link, index) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        links.forEach((l) => l.classList.remove('active'));
        link.classList.add('active');

        targets.forEach((target) => {
          if (target) {
              target.style.display = 'none';
          }
        });
        const selectedTarget = targets[index];
        if (selectedTarget) {
            selectedTarget.style.display = 'block';
        }

        window.scroll({top: 0, behavior: "smooth"});
      });
    });
  } else {
    window.addEventListener('scroll', () => {
      let active_idx = 0;
      for (let i = 0; i < targets.length; i++) {
        if (!isScrolledPastElement(targets[i])) {
          break;
        }
        active_idx = i;
      }
      links.forEach((link, i) => {
        if (i === active_idx) {
          link.classList.add('active');
        } else {
          link.classList.remove('active');
        }
      });
    });
  }


  function isScrolledPastElement(element: HTMLElement | null) {
    if (element == null) return false;
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    // add 120px from scroll-margin-top
    return window.pageYOffset + 120 > elementPosition;
  }
});
