import $ from 'jquery';

export const productCommerceSkuSelectModal = () =>
  $(() => {
    $('.commerce_purchase_button-btn, .PostUsedProductsList__commerce-btn').on(
      'click',
      function () {
        $('#modal-background').show();
        const targetModalId = $(this).attr('data-target');
        $(`#${targetModalId}`).show();
        // 親要素が`content-visibility:auto;`の時、子孫要素の`position:fixed;`およびz-indexが効かないので回避
        $(this).closest('.ProductListArticle__item').css('content-visibility', 'visible');
      },
    );

    $('.product-sku-select-modal__close, .PostUsedProductsList__commerce-btn__close, #modal-background').on(
      'click',
      function () {
        $('#modal-background').hide();
        $('.product-sku-select-modal').hide();
      },
    );
  });
