export const insertCommerceButton = () => {
  const commerce_container: HTMLElement | null = document.querySelector('.product-commerce__container');
  if (commerce_container !== null) {
    const id = commerce_container.dataset['productId'];
    fetch(`/products/${id}/commerce_button`).then(async response => {
      const html = await response.text();
      commerce_container.insertAdjacentHTML('beforeend', html);
    });
  }
};
