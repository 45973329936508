import $ from 'jquery';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const onPrivateLoad = (js_include: string, callback: () => void) => {
  const isOnPrivate = (js_include: string): boolean => {
    const data = $('body').attr('data-js-include');

    if (typeof data === 'undefined') {
      return false;
    }

    return data.indexOf(js_include) >= 0;
  };

  return $(() => {
    if (isOnPrivate(js_include)) {
      return callback();
    }
  });
};
