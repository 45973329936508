// TODO: types.ts は, グローバルに使われる型定義の暫定的な置き場
// ドメインに依存した型定義は置かない (`Product`, `Pattern` など)

import { LoggerService } from './service/LoggerService';

declare global {
  interface Window {
    isLoggedIn: boolean;
    openLoginModal(type?: string): void;
    loggerService: LoggerService;
  }
}
