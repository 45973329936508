import $ from 'jquery';

// ## modalを実装する際は以下の手順
// - modalを開くためのボタンに `.LipsModal__openModal` を当てる
//   - デフォルトで以下の挙動にしてあるので、適宜必要に応じて個別にスタイルを当てる
//     - PC表示は、画面から飛び出さない範囲で中身のコンテンツに応じて高さが決まり画面中央に配置
//     - SP表示は、全画面表示
// - modalを閉じるボタンに、 `.LipsModal__close` を当てる
// - modalの一番上の階層に `.LipsModal` を当てる
//   - 必要に応じてそれと同じ階層に以下を当てる
//     - `.LipsModal__from-left` → モバイルのみ左から出てくる
//     - `.LipsModal__from-bottom` → モバイルのみ下からアニメーションしながら出てくる

// modalを開きつつ、元の画面のスクロール位置を固定する
export const LipsModal = () => $(() => {
  $('.LipsModal__openModal').on('click', function() {
    const scrollY = window.scrollY;

    const modalClassName = $(this).data('open-modal-class');
    const modal = $(`.${modalClassName}`);
    if (modal.length === 0) return;

    $('#modal-background').show();
    modal.addClass('open');
    $('body').addClass('modal_open');
    // NOTE: modal_openを付与した後だとwindow.scrollYは0になる
    const body = document.body;
    body.style.top = `-${scrollY}px`;
  });

  $('.LipsModal__close, #modal-background').on('click', function() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.top = '';
    $('body').removeClass('modal_open');
    $('.LipsModal').removeClass('open');
    $('#modal-background').hide();
    // LipsModalではない他のモーダルの場合に
    // e.g. $(".user-clip-modal__close, #modal-background").on('click', ...)
    // スクロール位置を0に戻してしまっていたのでguard
    if (scrollY === '') return;
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  });
});
