type SearchEvent = {
  url: '/api/logs/beacon_event';
  params: {
    text: string;
    category: string;
  };
};

type LogEvent = SearchEvent;

/**
 * event_logに諸々送るためのservice層
 */
export class LoggerService {
  // eslint-disable-next-line class-methods-use-this
  fireEvent(logEvent: LogEvent) {
    // sendBeaconで送ると非同期で送信されるし、次のページの画面遷移を阻害しないので良い。
    navigator.sendBeacon(logEvent.url, JSON.stringify(logEvent.params));
  }
}
