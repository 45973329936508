import $ from 'jquery';

export const commerceLoading = () =>
  $(() => {
    function loadingThenTransition(event: any) {
      event.preventDefault();
      const ele = $(event.currentTarget);
      ele.off('click');
      const loading = $('#list-item-sku__loading');
      loading.fadeIn(1, () => {
        setTimeout(() => {
          event.currentTarget.click();
          ele.on('click', loadingThenTransition);
        }, 50);
      });
    }
    $('.list-item-sku__cart-button').on('click', loadingThenTransition);

    window.history.replaceState(null, document.getElementsByTagName('title')[0].innerHTML, null);
    window.addEventListener('popstate', () => {
      const loading = $('#list-item-sku__loading');
      loading.hide();
    });
  });
