import $ from 'jquery';
import ClipboardJS from 'clipboard';
import toastr from 'toastr';

export const webviewReferralGacha = () =>
  $(() => {
    const clipboard = new ClipboardJS('.gacha-copy-code');

    // 成功した時のイベント
    clipboard.on('success', (e) => {
      if (e.action === 'copy') {
        toastr.success('コピーしました', undefined, { positionClass: 'toast-top-center' });
      }
    });

    window.addEventListener('DOMContentLoaded', () => {
      const is_created_referral_gacha_ticket = $('.campaign-gacha__created_referral_gacha_ticket').val();
      if (is_created_referral_gacha_ticket === 'true') {
        toastr.success('ガチャの回数が１回分増えました！', '紹介コードが適用されました', {
          positionClass: 'toast-top-center',
          timeOut: 8000,
        });
      }
    });

    $('.campaign-gacha').on('click', '.campaign-gacha__referral-form-container__form-container__button', () => {
      const referralUserId = $('.campaign-gacha__referral-form-container__form-container__text-field').val();
      if (!referralUserId || referralUserId === '') return;

      $.ajax({
        url: `${window.location.pathname}/referral`,
        method: 'POST',
        data: { referral_user_id: referralUserId },
      })
        .done((res) => {
          $('.campaign-gacha__lottery-button').replaceWith(res);
          toastr.success('ガチャの回数が１回分増えました！', '紹介コードが適用されました', {
            positionClass: 'toast-bottom-center',
            timeOut: 8000,
          });
          $('.campaign-gacha__referral-form-container').hide();
        })
        .fail((res) => {
          toastr.error(res.responseJSON.message, undefined, {
            positionClass: 'toast-bottom-center',
          });
        });
    });
  });
