import $ from 'jquery';

export const postsShow = () => $(() => {
  const ContentAreaInner = $('.post-show__content-inner');
  const ContentHeight =  $('.post-show__content-sentence').height();
  const ReadMoreButton = $('.post-show__read-more_post-content');

  // ページ表示時に高さ960px以下の時は全表示し、上回る場合は残りの部分を非表示にしておく
  if (ContentHeight && ContentHeight > 960) {
    ReadMoreButton.addClass('is_show');
  } else {
    ContentAreaInner.addClass('is_open');
  }

  ReadMoreButton.click(function () {
    ContentAreaInner.addClass('is_open');
    $(this).hide();
  });

  $('.post-show__read-more_comment-list').click(function() {
    const parent = $('.post-show__comments-list');
    parent.find('.post-show__comments-children-item').css('display', 'block');
    parent.find('.comments-item').css('display', 'flex');
    $(this).hide();
  });

  const moviePostVideo = $('#movie_post_video');
  if (moviePostVideo) {
    $('#scroll-to-top').hide();

    $(window).on('scroll', function() {
      const moviePostOffset = moviePostVideo.offset();
      const moviePostHeight = moviePostVideo.height();
      const windowScrollTop = $(window).scrollTop();

      if (!(moviePostOffset && moviePostHeight && windowScrollTop)) return;
      if (moviePostOffset.top + moviePostHeight < windowScrollTop) {
        $('#scroll-to-top').fadeIn();
      } else {
        $('#scroll-to-top').fadeOut();
      }
    });
  }
  if ($('.splide').length > 0) {
    sliderFromSprideAndGaralley();
  }
});

const sliderFromSprideAndGaralley = () => {
  // @ts-ignore
  const splide: Splide = new Splide('.splide', { type: 'loop', arrows: false });
  splide.mount();

  const { Elements } = splide.Components;
  Elements.slides.forEach(function (slide: HTMLElement) {
    $(slide).children('.post-show__media-link').attr('data-fancybox', 'media-garalley');
  })

  // // PCのみ表示する小さい画像の要素
  const mediaListItem = '.post-show__media-list-medium';
  const currentItemClassName = 'current-item';

  splide.on('move', function (newIndex: number) {
    $(mediaListItem).removeClass(currentItemClassName);
    $(mediaListItem).eq(newIndex).addClass(currentItemClassName);
  });

  $(mediaListItem).eq(0).addClass(currentItemClassName);

  $(mediaListItem).on('click', function () {
    $(mediaListItem).removeClass(currentItemClassName);
    splide.go($(mediaListItem).index(this))
    $(this).addClass(currentItemClassName);
  });

  $(document).on('afterShow.fb', function( e, instance, slide ) {
    splide.go(slide.index);
    $(mediaListItem).removeClass(currentItemClassName);
    $(mediaListItem).eq(slide.index).addClass(currentItemClassName);

    const videoElement = slide.$slide?.find('video');
    const muted = videoElement?.attr('data-muted') || false;

    videoElement?.prop('muted', muted);
    videoElement?.off('volumechange.lips');

    if (muted) {
      videoElement?.on('volumechange.lips', () => {
        // muted解除できなくする
        videoElement.prop('muted', muted);
      });
    }
  });
}
