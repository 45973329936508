import $ from 'jquery';
import { isMobile } from './isMobile';

export const hoverAutoPlayMovie = () => $(() => {
    // pc only hover autoplay
    if (isMobile()) { return; }
    const cells = Array.from(document.querySelectorAll<HTMLElement>('.PostMoviePickup__cell'));
    const createVideoTag = (url: string): HTMLVideoElement => {
        const video = document.createElement('video');
        video.className = 'PostMoviePickup__video';
        video.src = url;
        video.loop = true;
        video.autoplay = true;
        video.muted = true;
        video.controls = false;
        video.setAttribute('playsinline', 'true');
        video.setAttribute('webkit-playsinline', 'true');
        return video;
    };

    for (const cell of cells) {
        cell.addEventListener('mouseover', () => {
            const element = cell.querySelector<HTMLElement>('.PostMoviePickup__cover');
            const video_url = element?.dataset.videoUrl ?? '';
            if (video_url.length === 0) { return; }
            const videoElement = element?.parentElement?.querySelector('video');
            if (videoElement == null) {
                const videoTag = createVideoTag(video_url);
                element?.after(videoTag);
            } else {
                videoElement?.play();
            }
        });
    }

    for (const cell of cells) {
        cell.addEventListener('mouseleave', () => {
            const video = cell.querySelector('video');
            if ((video?.currentTime ?? 0) > 0) {
                video?.pause();
            }
        });
    }
});
