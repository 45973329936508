import $ from 'jquery';
import { Product, Pattern, ProductPatterns, FeatureLabel } from './products-show';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const productPatternsSelectModal = () =>
  $(() => {
    const escapeHtml = function (string?: string): string {
      if (typeof string !== 'string') {
        return '';
      }

      const entityMap: { [entity: string]: string } = {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
      };
      return string.replace(/[&'`"<>]/g, (s) => entityMap[s]);
    };

    const sort_ui_html = (product_patterns: Array<Pattern>, has_pc_label: boolean, selected_sort_key?: string): string => {
      return `\
<li class="ProductPatternsSelectModal__sort">
<figure class="ProductPatternsSelectModal__figure">
  <div class="ProductPatternsSelectModal__figure-flex">
    <p class="ProductPatternsSelectModal__sort-count"><span>${product_patterns.length}</span>件</p>
    <div class="ProductPatternsSelectModal__sort-dropdown">
      <div class="ProductPatternsSelectModal__sort-dropdown__container">
      <select class="ProductPatternsSelectModal__sort-dropdown__select">
        <option value="default" ${selected_sort_key === 'default' ? 'selected' : ''}>指定なし</option>
        <option value="popular" ${selected_sort_key === 'popular' ? 'selected' : ''}>人気順</option>
        <option value="latest" ${selected_sort_key === 'latest' ? 'selected' : ''}>発売日が新しい順</option>
        ${has_pc_label ? `
          <option value="popular_with_spring_color" ${selected_sort_key === 'popular_with_spring_color' ? 'selected' : ''}>イエベ春に人気順</option>
          <option value="popular_with_summer_color" ${selected_sort_key === 'popular_with_summer_color' ? 'selected' : ''}>ブルベ夏に人気順</option>
          <option value="popular_with_autumn_color" ${selected_sort_key === 'popular_with_autumn_color' ? 'selected' : ''}>イエベ秋に人気順</option>
          <option value="popular_with_winter_color" ${selected_sort_key === 'popular_with_winter_color' ? 'selected' : ''}>ブルベ冬に人気順</option>
        ` : ''}
      </select>
    </div>
  </div>
</figure>
</a>
</li>\
`;
    };

    const onSortChange = () => {
      const sort_key = $('.ProductPatternsSelectModal__sort-dropdown__select').val() || '';
      $('.ProductPatternsSelectModal__list').empty();
      $('.ProductPatternsSelectModal__loading').show();
      generate_modal(sort_key.toString());
    };

    const pattern_list_html = function (product: Product, pattern: Pattern): string {
      let html = `\
<li class="ProductPatternsSelectModal__item">
<a class="ProductPatternsSelectModal__link gt-product-patterns-select-modal__link" href="/product_patterns/${escapeHtml(pattern.id.toString(10))}">
  <figure class="ProductPatternsSelectModal__figure">
    <div class="ProductPatternsSelectModal__figure-flex">
    <img src=" ${escapeHtml(pattern.thumb_url)}" alt="${escapeHtml(product.name)} ${escapeHtml(
      pattern.name,
    )}" loading="lazy" class="lazy ProductPatternsSelectModal__figure-thumb" onload="this.style.backgroundColor = 'transparent';">
    <figcaption class="ProductPatternsSelectModal__figure-caption">
      <p class=ProductPatternsSelectModal__figure-caption-txt>${escapeHtml(pattern.name)}</p>
      <ul class=ProductPatternsSelectModal__figure-caption-list>\
`;
      if (pattern.release_date) {
        html += `\
<li class=ProductPatternsSelectModal__figure-caption-listItem>
${escapeHtml(pattern.release_date)}発売
</li>\
`;
      }
      html += `\
<li class=ProductPatternsSelectModal__figure-caption-listItem>
クチコミ数： ${escapeHtml(pattern.post_count.toString(10))}
</li>\
`;
      if (pattern.status) {
        html += `\
<li class=ProductPatternsSelectModal__figure-caption-listItem>
    ${escapeHtml(pattern.status_i18n)}
</li>\
`;
      }
      html += `\
      </ul>\
      `;
      if (pattern.feature_labels) {
        html += `\
          <ul class=ProductPatternsSelectModal__featureLabel>\
          `;
        $.each(pattern.feature_labels, (i: number, label: FeatureLabel) => {
          html += `\
            <li class=ProductPatternsSelectModal__featureLabel__listItem style="${escapeHtml(label.label_style)}">
              ${escapeHtml(label.label_text)}
            </li>\
            `;
        });
        if (pattern.commerce_sales_status === 'available') {
          html += `\
            <li class=ProductPatternsSelectModal__featureLabel__salesStatusTag>
              在庫あり
            </li>\
            `;
        }
        html += `\
          </ul>\
          `;
      }
      html += `\
    </figcaption>
    </div>
`;

      if (pattern.post_thumb_urls.length > 0) {
        html += `\
    <div class="ProductPatternsSelectModal__figure-post-thumbs">
  `;

        [...Array(5)].forEach((_, i) => {
          const url = pattern.post_thumb_urls[i];

          if (url) {
            html += `\
        <img src="${escapeHtml(
          url,
        )}" loading="lazy" class="lazy ProductPatternsSelectModal__figure-post-thumbs-cell" onload="this.style.backgroundColor = 'transparent';">
      `;
          } else {
            html += `\
        <div class="ProductPatternsSelectModal__figure-post-thumbs-cell"></div>
      `;
          }
        });

        html += `\
    </div>
  `;
      }

      html += `\
    </figure>
  </a>
</li>\
`;
      return html;
    };

    const generate_modal = (sort_key?: string) => {
      if ($('.ProductPatternsSelectModal__list').find('li').length <= 0) {
        const pathString = location.pathname;

        if (typeof pathString !== 'string') {
          return;
        }

        const path = pathString.split('/');

        $.ajax({
          url: '/products/product_patterns_select',
          type: 'GET',
          data: {
            product_id: path[1] === 'products' ? path[2] : undefined,
            product_pattern_id: path[1] === 'product_patterns' ? path[2] : undefined,
            sort_key,
          },
          dataType: 'json',
        }).done(function (data: { product: Product; product_patterns: Array<Pattern> }) {
          $('.ProductPatternsSelectModal__loading').hide();
          $('.ProductPatternsSelectModal__list').append(sort_ui_html(data.product_patterns, data.product.has_pc_label, sort_key));
          adjustWidth($('.ProductPatternsSelectModal__sort-dropdown__select')[0] as HTMLSelectElement);
          $.each(data.product_patterns, (i: number, pattern: Pattern) => {
            $('.ProductPatternsSelectModal__list').append(pattern_list_html(data.product, pattern));
          });
        });
      }
    };

    const adjustWidth = (select: HTMLSelectElement) => {
      const text = select.options[select.selectedIndex].text;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return;
      context.font = getComputedStyle(select).fontSize + ' ' + getComputedStyle(select).fontFamily;
      const width = context.measureText(text).width;
      select.style.width = width + 47 + 'px'; // 45px はパディングやボーダーのための余白
    };

    $(
      '.product-show-fvAB__images__showAllPatterns, .ProductPatternsShow__info-show-all-link, .product-patterns-list-selection__info-show-all-link, .ProductPatternsListForProductShow__info-show-all, .ProductTabMenu__patternsItem, .ProductPatternsList__showAll',
    ).on('click', function () {
      generate_modal();
      $('.ProductPatternsSelectModal').show();
      $('#modal-background').show();
    });

    $('.ProductPatternsSelectModal__close, #modal-background').on('click', function () {
      $('.ProductPatternsSelectModal').hide();
      $('#modal-background').hide();
    });

    $('.ProductPatternsSelectModal').on('change', '.ProductPatternsSelectModal__sort-dropdown__select', onSortChange);
  });
