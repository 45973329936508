import $ from 'jquery';

export const productClipAppInstallModal = () => $(() => {
  $('.ProductActionButtons__button--clip').on('click', function () {
    $('#modal-background').show();
    $('.ProductClipAppInstallModal').show();
  });

  const hideModal = () => {
    $('#modal-background').hide();
    $('.ProductClipAppInstallModal').hide();
  }

  $('#modal-background').on('click', hideModal);
  $('.ProductClipAppInstallModal__buttons--close-button').on('click', hideModal);
});
