import $ from 'jquery';

export const productCommerceDynamicSkuSelectModal = () =>
  $(() => {
    $('.commerce_purchase_button-btn, .PostUsedProductsList__commerce-btn').on(
      'click',
      async function () {
        const productId = $(this).attr('data-product-id');
        const modalHtml = await fetch(`/products/${productId}/commerce_products`).then(response => response.text());
        $(modalHtml).appendTo("body");
        $('#modal-background').show();
        const targetModalId = $(this).attr('data-target');
        const targetModal = $(`#${targetModalId}`);
        targetModal.show();
        // 親要素が`content-visibility:auto;`の時、子孫要素の`position:fixed;`およびz-indexが効かないので回避
        $(this).closest('.ProductListArticle__item').css('content-visibility', 'visible');
        $('.product-sku-select-modal__close, .PostUsedProductsList__commerce-btn__close, #modal-background').on(
          'click',
          function () {
            $('#modal-background').hide();
            $('.product-sku-select-modal').remove();
          },
        );
      },
    );
  });
