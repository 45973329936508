import $ from 'jquery';
import './types';

export const productLike = () => {
  $(document).on('ready page:load', function () {
    $('.clip-button').click(function (event) {
      event.stopPropagation();
      event.preventDefault();

      if (window.isLoggedIn) {
        const target = $(event.currentTarget);
        const selected = target.hasClass('is-selected');

        $.ajax({
          url: selected ? '/clips/0' : '/clips',
          method: selected ? 'DELETE' : 'POST',
          data: {
            clippable_id: target.attr('clippable_id'),
            clippable_type: target.attr('clippable_type')
          }
        }).done(function () {
          const clipCount = target.data('clip-count');
          const newClipCount = selected ? clipCount - 1 : clipCount + 1;

          target.data('clip-count', newClipCount);

          if (selected) {
            target.removeClass('is-selected');
            target.text(newClipCount);
          } else {
            target.addClass('is-selected');
            target.text(newClipCount);
          }
        });
      } else {
        const type = $(event.currentTarget).attr('clippable_type');

        switch (type) {
          case "Post":
            window.openLoginModal('post-action');
            break;
          case "Product":
            window.openLoginModal('product-clip');
            break;
          case "ProductPattern":
            window.openLoginModal('product-pattern-clip');
            break;
          default:
            break;
        }
      }
    });
  });
};
