import $ from 'jquery';

export const autoPlayMovie = () => $(() => {
    const elements = Array.from(document.querySelectorAll<HTMLElement>('.AutoPlayMovie'));
    const urls = elements.map((e) => e.dataset.videoUrl ?? '');
    const valid_url_indexes = urls.flatMap((e, i) => (e.length > 0 ? i : []));
    if (valid_url_indexes.length === 0) {
        return;
    }
    const createVideoTag = (url: string): HTMLVideoElement => {
        const video = document.createElement('video');
        video.className = 'PostMoviePickup__video';
        video.src = url;
        video.loop = true;
        video.autoplay = true;
        video.muted = true;
        video.controls = false;
        video.setAttribute('playsinline', 'true');
        video.setAttribute('webkit-playsinline', 'true');
        return video;
    };
    const video_hash: { [key: string]: HTMLVideoElement } = {};
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            const element = entry.target as HTMLElement;
            const video_url = element.dataset.videoUrl ?? '';
            if (entry.isIntersecting) {
                if (video_hash[video_url] === undefined) {
                    const video = createVideoTag(video_url);
                    element.after(video);
                    video_hash[video_url] = video;
                } else {
                    video_hash[video_url]?.play();
                }
            } else {
                video_hash[video_url]?.pause();
            }
        });
    }, { threshold: 0.7 });
    valid_url_indexes.forEach((i) => {
        observer.observe(elements[i]);
    });
});
