import $ from 'jquery';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const productPurchaseBtnModal = () => $(() => {
  $('.ProductBottomPurchaseBtn__openModal').on('click', function() {
    $('#modal-background').show();
    $('.ProductBottomPurchaseBtn__modal').show();
  });

  $('.ProductBottomPurchaseBtn__modal-close, #modal-background').on('click', function() {
    $('#modal-background').hide();
    $('.ProductBottomPurchaseBtn__modal').hide();
  });
});
