import $ from 'jquery';

export const openImageModal = () => {
  const bgId: string = 'modal-bg';

  window.openImageModal = (modal_id: string, read_key: string | undefined) => {
    const modal_ele = document.getElementById(modal_id);
    if (modal_ele === null) {
      return;
    }
    if (read_key !== undefined && read_key !== '') {
      // https://developer.mozilla.org/ja/docs/Web/API/Document/cookie#example_3_do_something_only_once
      const regexp = new RegExp(`(?:(?:^|.*;\\s*)${read_key}\\s*\\=\\s*([^;]*).*$)|^.*$`);
      if (document.cookie.replace(regexp, '$1') === 'true') {
        return;
      }
    }

    $('body').append(`<div id="${bgId}"></div>`);
    $(`#${bgId}`).fadeIn(300);
    $(modal_ele).fadeIn(300);
    $('.image-modal__close-btn')[0].onclick = (event: any) => {
      const modal = $(event.currentTarget).parent();
      modal.fadeOut(300);
      $(`#${bgId}`).fadeOut(300, function remove() {
        this.remove();
      });
      if (read_key !== undefined && read_key !== '') {
        document.cookie = `${read_key}=true`;
      }
    };
  };
};

declare global {
  interface Window {
    openImageModal(modal_id: string, read_key: string | undefined): void;
  }
}
