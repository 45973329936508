import $ from 'jquery';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const CURRENT_THUMB_CLASS_NAME = 'current-thumb'

export const productsArticleLikeList = () => {
  Array.from(document.querySelectorAll<HTMLElement>('.ProductsArticleLikeList__imageContentsContainer')).forEach(element => {
    const thumbnails = Array.from(element.querySelectorAll('.ProductsArticleLikeList__thumbArea-listItem'));
    thumbnails[0].classList.add(CURRENT_THUMB_CLASS_NAME);

    if (thumbnails.length <= 1) return;

    // @ts-ignore
    const splide = new Splide(element.querySelector('.splide'), {
      type: 'loop',
      arrows: false,
      pagination: false,
    }).mount();

    thumbnails.forEach((thumbnail, i) => {
      thumbnail.addEventListener('click', () => {
        splide.go(i);

        thumbnails.forEach(thumb => {
          thumb.classList.remove(CURRENT_THUMB_CLASS_NAME);
        })

        thumbnail.classList.add(CURRENT_THUMB_CLASS_NAME);
      })
    });

    splide.on('move', function (newIndex: number) {
      thumbnails.forEach(thumb => {
        thumb.classList.remove(CURRENT_THUMB_CLASS_NAME);
      })
      thumbnails[newIndex].classList.add(CURRENT_THUMB_CLASS_NAME);
    });
  })
}
