import $ from 'jquery';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const productReviewRefinedPostModal = () =>
  $(() => {
    const selectableRateMax = Number($('.ProductReviewRefinedPostModal__rate-input1').attr('max'));
    const selectableRateMin = Number($('.ProductReviewRefinedPostModal__rate-input1').attr('min'));

    // キーワード入力のクリアボタンの挙動
    const toggleTextFormClearButton = () => {
      if (($('.ProductReviewRefinedPostModal__text-form-input').val() as string).length > 0) {
        $('.ProductReviewRefinedPostModal__text-form-clear-button').show();
      } else {
        $('.ProductReviewRefinedPostModal__text-form-clear-button').hide();
      }
    };

    toggleTextFormClearButton();

    $('.ProductReviewRefinedPostModal__text-form-input').on('keyup', function () {
      toggleTextFormClearButton();
    });

    const clearTextForm = () => {
      $('.ProductReviewRefinedPostModal__text-form-clear-button').hide();
      $('.ProductReviewRefinedPostModal__text-form-input').val('');
    };

    const clearPatternForm = () => {
      $('.ProductReviewRefinedPostModal__section-pattern').hide();
      $('.ProductReviewRefinedPostModal__section-pattern input[name="product_pattern_id"]').val('');
    };

    $('.ProductReviewRefinedPostModal__text-form-clear-button').on('click', function () {
      clearTextForm();
    });

    // 検索ボタンの挙動
    $('.ProductReviewRefinedPostModal__search_btn').on('click', function () {
      const form = document.querySelector<HTMLFormElement>('.ProductReviewRefinedPostModal form');
      if (!form) return;

      const sort = form.querySelector<HTMLInputElement>('input[name="sort"]')?.value || '';
      const age_type = form.querySelector<HTMLInputElement>('input[name="age_type"]:checked')?.value || '';
      const skin_type = form.querySelector<HTMLInputElement>('input[name="skin_type"]:checked')?.value || '';
      const text = form.querySelector<HTMLInputElement>('input[name="text"]')?.value || '';
      const category_feature_name = form.querySelector<HTMLInputElement>('input[name="category_feature_name"]:checked')?.value || '';

      const rateRange = getRateRange();
      const rate = rateRange.max === selectableRateMax && rateRange.min === selectableRateMin ? '' : `${rateRange.min}-${rateRange.max}`;

      const only_this_product = $('.CheckBox__option-check-box[name="only_this_product"]').prop('checked') ? 'true' : '';
      const filter_gifting_posts = $('.CheckBox__option-check-box[name="filter_gifting_posts"]').prop('checked') ? 'true' : '';

      const product_pattern_id = form.querySelector<HTMLInputElement>('input[name="product_pattern_id"]')?.value || '';

      const params = { sort, age_type, skin_type, text, rate, only_this_product, category_feature_name, filter_gifting_posts, product_pattern_id };
      const paramString = new URLSearchParams(Object.fromEntries(Object.entries(params).filter(([, v]) => v !== ''))).toString();

      window.location.href = paramString === '' ? location.pathname : `${location.pathname}?${paramString}`;
    });

    // 選択されている年代の条件と掛け合わせると投稿が存在しない肌質の選択肢をdisableにする
    const toggleDisableSkinTypes = (element: JQuery<HTMLElement>) => {
      const selectableSkinTypes = element.data('displayable-skin-types');

      $('input:radio[name="skin_type"]').each(function () {
        // 全肌質は常に表示
        if ($(this).val() === '') return;

        $(this).prop('disabled', !selectableSkinTypes.includes($(this).val()));
      });
    };

    toggleDisableSkinTypes($('input:radio[name="age_type"]:checked'));

    $('input:radio[name="age_type"]').on('click', function () {
      toggleDisableSkinTypes($(this));
    });

    // 選択されている肌質の条件と掛け合わせると投稿が存在しない年代の選択肢をdisableにする
    const toggleDisableAgeTypes = (element: JQuery<HTMLElement>) => {
      const selectableAgeTypes = element.data('displayable-age-types');

      $('input:radio[name="age_type"]').each(function () {
        // 全年代は常に表示
        if ($(this).val() === '') return;

        $(this).prop('disabled', !selectableAgeTypes.includes($(this).val()));
      });
    };

    toggleDisableAgeTypes($('input:radio[name="skin_type"]:checked'));

    $('input:radio[name="skin_type"]').on('click', function () {
      toggleDisableAgeTypes($(this));
    });

    // おすすめのトピック
    // 2度押しで選択解除
    let categoryFeatureNameChecked = $('input:radio[name="category_feature_name"]:checked').val();
    $('input:radio[name="category_feature_name"]').on('click', function () {
      if ($(this).val() === categoryFeatureNameChecked) {
        $(this).prop('checked', false);
        categoryFeatureNameChecked = undefined;
      } else {
        categoryFeatureNameChecked = $(this).val();
      }
    });

    // 評価の絞り込みフォーム用
    const getRateRange = () => {
      const val1 = $('.ProductReviewRefinedPostModal__rate-input1').val() as number;
      const val2 = $('.ProductReviewRefinedPostModal__rate-input2').val() as number;

      const max = Math.max(val1, val2);
      const min = Math.min(val1, val2);

      return { max, min };
    };

    const setRateRange = () => {
      const rateRange = getRateRange();

      const step = 100 / (selectableRateMax - selectableRateMin);
      document
        .querySelector<HTMLElement>('.ProductReviewRefinedPostModal__rate-input1')
        ?.style.setProperty('--max', `${(rateRange.max - selectableRateMin) * step}%`);
      document
        .querySelector<HTMLElement>('.ProductReviewRefinedPostModal__rate-input1')
        ?.style.setProperty('--min', `${(rateRange.min - selectableRateMin) * step}%`);

      $('.ProductReviewRefinedPostModal__rate-max').text(`★${rateRange.max}`);
      $('.ProductReviewRefinedPostModal__rate-min').text(`★${rateRange.min}`);
    };

    setRateRange();

    document.querySelector<HTMLElement>('.ProductReviewRefinedPostModal__rate-input1')?.addEventListener(`input`, () => {
      setRateRange();
    });

    document.querySelector<HTMLElement>('.ProductReviewRefinedPostModal__rate-input2')?.addEventListener(`input`, () => {
      setRateRange();
    });

    $('.ProductReviewRefinedPostModal__pattern-clear').on('click', () => {
      clearPatternForm();
    });

    // クリアボタンの処理
    $('.ProductReviewRefinedPostModal__clear_btn').on('click', function () {
      $('.ProductReviewRefinedPostModal #age_type_not_set').prop('checked', true);
      $('.ProductReviewRefinedPostModal #skin_type_not_set').prop('checked', true);
      clearTextForm();
      $('.ProductReviewRefinedPostModal__rate-input1').val(selectableRateMin);
      $('.ProductReviewRefinedPostModal__rate-input2').val(selectableRateMax);
      setRateRange();
      $('.CircleCheckBox__option-check-box').prop('checked', false);
      $('input:radio[name="category_feature_name"]:checked').prop('checked', false);
      clearPatternForm();
    });
  });
