import $ from 'jquery';
import { breakPoint } from './const';

export const brandsShow = () => $(() => {
  const length = $('.splide__slide.brand-show__article').length;
  if (length === 0) return;

  // @ts-ignore
  new Splide('.brand-show__articles.splide', {
    autoWidth: true,
    gap: '16px',
    focus: length > 2 ? 'center' : 0,
    destroy: length === 1 ? 'completely' : length === 2,
    breakpoints: {
      985: {
        destroy: length === 1 ? 'completely' : false,
      },
      [breakPoint]: {
        padding: { left: '16px', right: '16px' },
        destroy: length === 1 ? 'completely' : false,
      }
    }
  }).mount();
})
