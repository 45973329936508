import $ from 'jquery';

export const christmasCoffret = () => $(() => {
  const pickupToggle = $('.ChristmasCoffret__pickup__toggle');
  const hidePickupLists = $('.ChristmasCoffret__pickup__listItem.hide')
  pickupToggle.click(function () {
    hidePickupLists.removeClass('hide');
    pickupToggle.css('display', 'none');
  })
});
