import $ from 'jquery';

export const cosmeticCalendarBrandAnchor = () => $(() => {
  const moreBtn = $('.CosmeticCalendarBrandAnchorList__more');
  const hiddenList = $('.CosmeticCalendarBrandAnchorList__item')
  moreBtn.click(function () {
    moreBtn.css('display', 'none');
    hiddenList.each(function () {
      $(this).css('display', 'block');
    })
  })
});
