import { onPrivateLoad } from './base/on_private_load';
import { LipsModal } from './lips-modal';
import { postsShow } from './posts-show';
import { brandsShow } from './brands-show';
import { postFeedbackModal } from './post-feedback-modal';
import { productCommerceSkuSelectModal } from './product-commerce-sku-select-modal';
import { productCommerceDynamicSkuSelectModal } from './product-commerce-dynamic-sku-select-modal';
import { productPatternsSelectModal } from './product-patterns-select-modal';
import { productPurchaseBtnModal } from './product-purchase-btn-modal';
import { productPurchaseEvent } from './product-purchase-event-handler';
import { productsArticleLikeList } from './products-article-like-list';
import { productClipAppInstallModal } from './product-clip-app-install-modal';
import { productElements } from './product-elements';
import { productsShow } from './products-show';
import { productPatternShow } from './product-pattern-show';
import { earnedPointBreakdownModal } from './earned-point-breakdown-modal';
import { productLike } from './product-like';
import { productPatternElements } from './product-pattern-elements';
import { commerceLoading } from './commerce-loading';
import { openImageModal } from './open-image-modal';
import { webviewGacha } from './webview/gacha';
import { webviewReferralGacha } from './webview/referral_gacha';
import { bottomWebToAppModal } from './bottom-web-to-app-modal';
import { autoPlayMovie } from './auto-play-movie';
import { hoverAutoPlayMovie } from './hover-auto-play-movie';
import { bindLogger } from './service/bindLogger';
import { productReviewRefinedPostModal } from './product-review-refined-post-modal';
import { mallPoint } from './mall-point';
import { scrollbar } from './scrollbar';
import { anchorWithContentVisibility } from './anchor-with-content-visibility';
import { anchorTab } from './anchor_tab';
import { usersShow } from './users-show';
import { usersEdit } from './users-edit';
import { christmasCoffret } from './christmas-coffret';
import { brandCategoryRankingsListAccordion } from './brand-category-rankings-list-accordion';
import { productPatternRankingIn } from './product-pattern-ranking-in';
import { cosmeticCalendarBrandAnchor } from './cosmetic-calendar-brand-anchor';
import { cosmeticCalendarCategoriesList } from './cosmetic-calendar-categories-list';
import { shoppingFaqIndex } from './shopping-faq-index';
import { search } from './search';

// すべてのページにおいて実行が必要 (したがって, これを先に実行しておく)
productLike();
bindLogger();

const commerceGacha = () => webviewGacha('commerce');
const campaignGacha = () => webviewGacha('campaign');

// 個々のページで実行される
onPrivateLoad('lips-modal', LipsModal);
onPrivateLoad('posts-show', postsShow);
onPrivateLoad('brands-show', brandsShow);
onPrivateLoad('post-feedback-modal', postFeedbackModal);
onPrivateLoad('product-commerce-sku-select-modal', productCommerceSkuSelectModal);
onPrivateLoad('product-commerce-dynamic-sku-select-modal', productCommerceDynamicSkuSelectModal);
onPrivateLoad('product-patterns-select-modal', productPatternsSelectModal);
onPrivateLoad('product-purchase-btn-modal', productPurchaseBtnModal);
onPrivateLoad('product-purchase-event-handler', productPurchaseEvent);
onPrivateLoad('product-clip-app-install-modal', productClipAppInstallModal);
onPrivateLoad('product-elements', productElements);
onPrivateLoad('product-pattern-elements', productPatternElements);
onPrivateLoad('products-article-like-list', productsArticleLikeList);
onPrivateLoad('products-article-like-list', productsArticleLikeList);
onPrivateLoad('products-show', productsShow);
onPrivateLoad('product-pattern-show', productPatternShow);
onPrivateLoad('earned-point-breakdown-modal', earnedPointBreakdownModal);
onPrivateLoad('commerce-loading', commerceLoading);
onPrivateLoad('open-image-modal', openImageModal);
onPrivateLoad('webview-gacha', commerceGacha);
onPrivateLoad('webview-campaign-gacha', campaignGacha);
onPrivateLoad('webview-referral-gacha', webviewReferralGacha);
onPrivateLoad('bottom-web-to-app-modal', bottomWebToAppModal);
onPrivateLoad('auto-play-movie', autoPlayMovie);
onPrivateLoad('hover-auto-play-movie', hoverAutoPlayMovie);
onPrivateLoad('product-review-refined-post-modal', productReviewRefinedPostModal);
onPrivateLoad('mall-point', mallPoint);
onPrivateLoad('scrollbar', scrollbar);
onPrivateLoad('anchor-with-content-visibility', anchorWithContentVisibility);
onPrivateLoad('anchor-tab', anchorTab);
onPrivateLoad('users-show', usersShow);
onPrivateLoad('users-edit', usersEdit);
onPrivateLoad('christmas-coffret', christmasCoffret);
onPrivateLoad('brand-category-rankings-list-accordion', brandCategoryRankingsListAccordion);
onPrivateLoad('product-pattern-ranking-in', productPatternRankingIn);
onPrivateLoad('cosmetic-calendar-brand-anchor', cosmeticCalendarBrandAnchor);
onPrivateLoad('cosmetic-calendar-categories-list', cosmeticCalendarCategoriesList);
onPrivateLoad('shopping-faq-index', shoppingFaqIndex);
onPrivateLoad('search', search);
