import $ from 'jquery';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const productPurchaseEvent = () => $(() => $('.ArticleItemProduct__btn-purchase, .PostUsedProductsList__btn-purchase, .ProductButtons__purchase, .ProductBottomPurchaseBtn__btn').on('click', function() {
  const productId = $(this).data('product-id');

  $.ajax({
    url: location.protocol + '//' + location.host + '/api/logs/event',
    method: 'post',
    data: {
      name: 'product_redirect',
      params: { id: productId }
    },
    headers: {
      'X_API_VERSION': '2'
    }
  });
}));
